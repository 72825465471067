
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
} from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import InputCheckbox from "@/components/console/inputs/InputCheckbox.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import moment, { Moment } from "moment-timezone";

type ScheduleData = {
  startAt: Moment;
  endAt: Moment;
};

export default defineComponent({
  name: "ScheduleRegisterModal",
  components: { ButtonBasic, InputCheckbox, InputDatePicker, BaseModal },
  props: {
    scheduleData: {
      type: Object as PropType<ScheduleData>,
      required: true,
    },
  },
  emits: ["closeModal", "saveSchedule"],
  setup(props, { emit }) {
    const state = reactive({
      date: "",
      startTime: "",
      endTime: "",
      endDate: "",
      scheduleForm: {
        startAt: computed(() => {
          return moment(`${state.date} ${state.startTime}`);
        }),
        endAt: computed(() => {
          return moment(`${state.endDate} ${state.endTime}`);
        }),
        isRepeat: false,
      },
      activeSaveBtn: computed(() => {
        return (
          state.scheduleForm.startAt.diff(state.scheduleForm.endAt) < 0 &&
          Number.isInteger(
            state.scheduleForm.endAt.diff(
              state.scheduleForm.startAt,
              "minutes"
            ) / 30
          )
        );
      }),
      reservedTime: computed(() => {
        let diffTime = state.scheduleForm.endAt.diff(
          state.scheduleForm.startAt,
          "minutes"
        );

        let h = Math.floor(diffTime / 60);
        let m = diffTime % 60;

        if (h === 0 && m > 0) {
          return `총 ${m}분`;
        } else if (h > 0 && m === 0) {
          return `총 ${h}시간`;
        } else if (h > 0 && m > 0) {
          return `총 ${h}시간 ${m}분`;
        } else {
          return "";
        }
      }),
      isSaveLoading: false,
    });

    onBeforeMount(() => {
      state.date = props.scheduleData.startAt.format("YYYY-MM-DD");
      state.endDate = props.scheduleData.endAt.format("YYYY-MM-DD");

      state.endTime = props.scheduleData.endAt.format("HH:mm");
      state.startTime = props.scheduleData.startAt.format("HH:mm");
    });

    const actions = {
      closeModal: () => {
        //note 데이터 레이어 송신으로 길어져서 막음
        if (state.isSaveLoading) {
          return;
        }
        emit("closeModal");
      },
      setDate: (value) => {
        state.date = moment(value).format("YYYY-MM-DD");
      },
      setStartAt: (value) => {
        state.startTime = moment(value).format("HH:mm");
      },
      setEndAt: (value) => {
        let formattedMinutes = moment(value).format("HH:mm");
        if (formattedMinutes === "00:00") {
          state.endDate = moment(state.date)
            .add(1, "days")
            .format("YYYY-MM-DD");
        } else {
          state.endDate = state.date;
        }
        state.endTime = formattedMinutes;
      },
      updateIsRepeat: (value) => {
        state.scheduleForm.isRepeat = value;
      },
      saveSchedule: () => {
        state.isSaveLoading = true;
        emit("saveSchedule", state.scheduleForm);
      },
    };

    return { state, actions };
  },
});
