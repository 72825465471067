<template>
  <base-modal-new
    title="예약 세션 관리로 이동할까요?"
    sub-title="예약된 일정의 정보를 더 자세히 확인할 수 있어요."
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="button-wrapper">
        <button-basic
          class="cancel-btn"
          text="취소"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          text="자세히 보러가기"
          @action="actions.moveToOpenHourReservation()"
        ></button-basic>
      </div>
    </template>
  </base-modal-new>
</template>

<script>
import { defineComponent } from "vue";
import BaseModalNew from "../../../../components/common/BaseModalNew";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default defineComponent({
  name: "ReservedScheduleCancelModal",
  components: { ButtonBasic, BaseModalNew },
  emits: ["closeModal", "moveToOpenHourReservation"],
  setup(props, { emit }) {
    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
      moveToOpenHourReservation: () => {
        emit("moveToOpenHourReservation");
      },
    };

    return { actions };
  },
});
</script>

<style src="./style.css" scoped></style>
