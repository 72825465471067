import { OpenSession, ClosedSession, CanceledSession } from "@/types/dataLayer";
import { cloneDeep } from "lodash";

const openSession: OpenSession = {
  event: "session_opened",
  studio_session_open: {
    service: "studio",
    creator_id: "",
    creator_account: "",
    creator_signup_date: "",
    creator_category: [],
    creator_name: "",
    creator_page: "",
    creator_url: "",
    open_date: "",
    open_session_quantity: 0,
    session_info: [],
  },
};

const closedSession: ClosedSession = {
  event: "session_closed",
  studio_session_open: {
    service: "studio",
    creator_id: "",
    creator_account: "",
    creator_category: [],
    creator_name: "",
    creator_page: "",
    creator_url: "",
    open_date: "",
    closed_session_quantity: 0,
    closed_date: "",
    session_info: [],
  },
};

const canceledSession: CanceledSession = {
  event: "session_creator_canceled",
  studio_session_creator_canceled: {
    service: "studio",
    creator_id: "",
    creator_account: "",
    creator_category: [],
    creator_name: "",
    creator_page: "",
    creator_url: "",
    creator_canceled_session_quantity: 0,
    creator_canceled_coin_quantity: 0,
    creator_canceled_session_date: "",
    session_info: [],
  },
};

const initDataLayer = (): any[] => window.dataLayer || [];

const copyEventObj = (
  eventName: "open" | "close" | "cancel"
): OpenSession | ClosedSession | CanceledSession => {
  if (eventName === "open") {
    return cloneDeep(openSession);
  } else if (eventName === "close") {
    return cloneDeep(closedSession);
  } else {
    return cloneDeep(canceledSession);
  }
};

const pushDataLayer = (eventObj): void => {
  window.dataLayer = initDataLayer();
  window.dataLayer.push(eventObj);
};

export { copyEventObj, pushDataLayer };
