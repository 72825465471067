
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import Calendar from "@/components/console/calendar/Calendar.vue";
import moment, { Moment } from "moment-timezone";
import { useStore } from "vuex";
import { CalendarSchedule } from "@/types/common";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import { ReservedSchedule } from "@/types/lectures";
import swal from "@/helper/swal.js";
import ScheduleRegisterModal from "@/pages/console/OpenHourSchedule/ScheduleRegisterModal/ScheduleRegisterModal.vue";
import ScheduleDeleteModal from "@/pages/console/OpenHourSchedule/ScheduleDeleteModal/ScheduleDeleteModal.vue";
import Tooltip from "@/components/console/tooltip/Tooltip.vue";
import helper from "@/helper";
import ReservedScheduleCancelModal from "@/pages/console/OpenHourSchedule/ReservedScheduleCancelModal/ReservedScheduleCancelModal.vue";
import { useRouter } from "vue-router";
import { dateFormat } from "@/helper/date";
import { cloneDeep } from "lodash";
import { copyEventObj, pushDataLayer } from "@/helper/dataLayerConsole";

type CalenderType = "M" | "W";
type TimeGrid = {
  startAt: Moment;
  endAt: Moment;
};

export default defineComponent({
  name: "OpenHourSchedule",
  components: {
    ReservedScheduleCancelModal,
    Tooltip,
    ScheduleDeleteModal,
    ScheduleRegisterModal,
    PageLoading,
    Calendar,
    ButtonBasic,
    PageHeader,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarComponent = ref();

    const monthType = "M";
    const weekType = "W";

    const state = reactive({
      pageLoading: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      lecture: computed(() => {
        return store.getters["lectures/getLecture"];
      }),
      schedules: computed(() => {
        return store.getters["lectures/lectureSchedules"];
      }),
      reservations: computed(() => {
        return store.getters["lectures/lecturesReservations"];
      }),
      calendarFormatSchedules: [] as CalendarSchedule[],
      calendarFormatReservations: [] as ReservedSchedule[],
      calendarType: monthType as CalenderType,
      showScheduleRegisterModal: false,
      scheduleModalData: {
        startAt: {} as Moment,
        endAt: {} as Moment,
      },
      scheduleCloseForm: {
        timeGrid: {} as TimeGrid,
        weekIndex: 0 as number,
      },
      scheduleModalWeekIndex: 0,
      showScheduleCloseModal: false,
      isTablet: false,
      showReservedScheduleCancelModal: false,
    });

    onBeforeMount(async () => {
      await getLecture();
      //note 달력에 보여주는 스케줄과 예약목록을 가져오는 로직 startOf, ednOf 는 7로 고정되어 있었으나, 다음달의 달력의 예약목록이 나오지 않아 추가함.
      await getSchedules(
        moment().startOf("months").add(-7, "days"),
        moment().endOf("months").add(14, "days")
      );
      await getReservations(
        moment().startOf("months").add(-7, "days"),
        moment().endOf("months").add(14, "days")
      );
      state.isTablet = helper.isTabletSize();
      state.pageLoading = false;
    });

    const getLecture = async () => {
      await store.dispatch("lectures/getLecture", {
        resourceId: state.user.lecture.resourceId,
      });
    };

    const getSchedules = async (from: Moment, to: Moment) => {
      await store
        .dispatch("lectures/getLectureSchedules", {
          resourceId: state.user.lecture.resourceId,
          data: {
            startAt: from.format("YYYY-MM-DD HH:mm:ss"),
            endAt: to.format("YYYY-MM-DD HH:mm:ss"),
          },
        })
        .then(async () => {
          await calenderFormatSchedule();
        });
    };

    const getReservations = async (from: Moment, to: Moment) => {
      await store
        .dispatch("lectures/getLectureReservations", {
          lectureResourceId: state.user.lecture.resourceId,
          data: {
            startAt: from.format("YYYY-MM-DD HH:mm:ss"),
            endAt: to.format("YYYY-MM-DD HH:mm:ss"),
            isPagination: false,
            isValid: true,
          },
        })
        .then(async () => {
          await calenderFormatReservations();
        });
    };

    const calenderFormatSchedule = () => {
      const scheduleCount = state.schedules.length;

      let schedules: CalendarSchedule[] = [];
      for (let i = 0; i < scheduleCount; i++) {
        schedules.push({
          startAt: moment(state.schedules[i].startAt),
          endAt: moment(state.schedules[i].endAt),
        });
      }

      state.calendarFormatSchedules = schedules;
    };

    const calenderFormatReservations = () => {
      const scheduleCount = state.reservations.length;

      let reservations: ReservedSchedule[] = [];
      for (let i = 0; i < scheduleCount; i++) {
        reservations.push({
          startAt: moment(state.reservations[i].startAt),
          endAt: moment(state.reservations[i].endAt),
          groupResourceId: state.reservations[i].resourceId,
          scheduleUser: state.reservations[i].scheduleUser,
        });
      }

      state.calendarFormatReservations = reservations;
    };

    const setWeekCalendarData = async (weekIndex) => {
      let fromDate = moment().add(weekIndex, "weeks").startOf("week");
      let toDate = moment().add(weekIndex, "weeks").endOf("week");

      await getSchedules(fromDate, toDate);
      await getReservations(fromDate, toDate);
    };

    const saveSchedules = (scheduleForm: {
      startAt: Moment;
      endAt: Moment;
      isRepeat: boolean;
    }) => {
      store
        .dispatch("lectures/postLectureSchedule", {
          resourceId: state.user.lecture.resourceId,
          data: {
            schedules: [
              {
                startAt: scheduleForm.startAt,
                endAt: scheduleForm.endAt,
                isRepeat: scheduleForm.isRepeat,
              },
            ],
          },
        })
        .then(async (res) => {
          await setWeekCalendarData(state.scheduleModalWeekIndex);
          setScheduleOpenDataLayer(scheduleForm, res);
          state.showScheduleRegisterModal = false;
          swal.messageAlert("예약 가능한 스케줄이 등록되었어요.");
        });
    };

    const setScheduleOpenDataLayer = (scheduleForm, resSchedules) => {
      let openSession = copyEventObj("open");

      //Note 스케줄 open
      let schedule;
      let sameDateOfSchedules;

      let diffTime = scheduleForm.endAt.diff(scheduleForm.startAt, "minutes");
      let openScheduleCounts = diffTime / 30;

      let schedules = cloneDeep(resSchedules);

      schedule = schedules.find(
        (element) =>
          moment.utc(element.startAt).format("YYYY-MM-DD HH:mm:ss") ===
          moment.utc(scheduleForm.startAt).format("YYYY-MM-DD HH:mm:ss")
      );

      if (scheduleForm.isRepeat) {
        sameDateOfSchedules = schedules
          .slice(openScheduleCounts * -15)
          .filter(
            (element) =>
              moment.utc(element.createdAt).format("YYYY-MM-DDTHH:mm[Z]") ===
              moment.utc(schedule.createdAt).format("YYYY-MM-DDTHH:mm[Z]")
          );
      } else {
        sameDateOfSchedules = schedules
          .slice(openScheduleCounts * -1)
          .filter(
            (element) =>
              moment
                .utc(element.createdAt)
                .diff(moment.utc(schedule.createdAt), "seconds") < 10
          );
      }
      // Todo 시간 utc 변환하기
      openSession["studio_session_open"].creator_id = state.user.resourceId;
      openSession["studio_session_open"].creator_account = state.user.email;
      openSession["studio_session_open"].creator_signup_date = dateFormat(
        state.user.createdAt,
        "YY-MM-DD HH:mm:ss"
      );

      for (let i = 0; i < state.lecture.tags.length; i++) {
        openSession["studio_session_open"].creator_category[i] = {
          category: state.lecture.tags[i].name,
          type: state.lecture.tags[i].type,
        };
      }
      openSession["studio_session_open"].creator_name =
        state.user.userProfile.displayName;
      openSession["studio_session_open"].creator_page =
        state.user.lecture.title;
      openSession[
        "studio_session_open"
      ].creator_url = `${process.env.VUE_APP_OPEN_HOUR_URL}/lecture/${state.user.lecture.resourceId}`;

      openSession["studio_session_open"].open_date = dateFormat(
        schedule.createdAt,
        "YY-MM-DD HH:mm:ss"
      );
      openSession["studio_session_open"].open_session_quantity =
        sameDateOfSchedules.length;
      for (let i = 0; i < sameDateOfSchedules.length; i++) {
        openSession["studio_session_open"].session_info[i] = {
          session_id: sameDateOfSchedules[i].resourceId,
          session_coin: state.user.userProfile.defaultCoin,
          session_start_date: dateFormat(
            sameDateOfSchedules[i].startAt,
            "YY-MM-DD HH:mm:ss"
          ),
          session_end_date: dateFormat(
            sameDateOfSchedules[i].endAt,
            "YY-MM-DD HH:mm:ss"
          ),
        };
      }

      pushDataLayer(openSession);
    };

    const setScheduleCloseDataLayer = (schedule, eventDate) => {
      let closedSession = copyEventObj("close");

      closedSession["studio_session_open"].creator_id = state.user.resourceId;
      closedSession["studio_session_open"].creator_account = state.user.email;

      for (let i = 0; i < state.lecture.tags.length; i++) {
        closedSession["studio_session_open"].creator_category[i] = {
          category: state.lecture.tags[i].name,
          type: state.lecture.tags[i].type,
        };
      }
      closedSession["studio_session_open"].creator_name =
        state.user.userProfile.displayName;
      closedSession["studio_session_open"].creator_page =
        state.user.lecture.title;
      closedSession[
        "studio_session_open"
      ].creator_url = `${process.env.VUE_APP_OPEN_HOUR_URL}/lecture/${state.user.lecture.resourceId}`;

      closedSession["studio_session_open"].open_date = dateFormat(
        schedule.createdAt,
        "YY-MM-DD HH:mm:ss"
      );
      closedSession["studio_session_open"].closed_session_quantity = 1;
      closedSession["studio_session_open"].closed_date = eventDate;

      closedSession["studio_session_open"].session_info[0] = {
        session_id: schedule.resourceId,
        session_coin: state.user.userProfile.defaultCoin,
        session_start_date: dateFormat(schedule.startAt, "YY-MM-DD HH:mm:ss"),
        session_end_date: dateFormat(schedule.endAt, "YY-MM-DD HH:mm:ss"),
      };

      pushDataLayer(closedSession);
    };

    const deleteLectureSchedule = async () => {
      let schedule = state.schedules.find((item) => {
        return (
          moment(item.startAt).format("YYYY-MM-DD HH:mm") ===
          moment(state.scheduleCloseForm.timeGrid.startAt).format(
            "YYYY-MM-DD HH:mm"
          )
        );
      });

      let eventDate = dateFormat(moment().utc(), "YYYY-MM-DD HH:mm");

      await store
        .dispatch("lectures/deleteLectureSchedule", {
          lectureResourceId: state.user.lecture.resourceId,
          lectureScheduleResourceId: schedule.resourceId,
        })
        .then(() => {
          setScheduleCloseDataLayer(schedule, eventDate);
          setWeekCalendarData(state.scheduleCloseForm.weekIndex);
          actions.closeScheduleDeleteModal();
          swal.messageAlert("예약 가능한 스케줄이 삭제되었어요");
        });
    };

    const cancelReservedSchedule = () => {
      state.showReservedScheduleCancelModal = true;
    };

    const actions = {
      setCalendarType: (value) => {
        state.calendarType = value;
        if (state.calendarType === monthType) {
          calendarComponent.value.actions.setMonthType();
        }

        if (state.calendarType === weekType) {
          calendarComponent.value.actions.setWeekType();
        }
      },
      setCalendarTypeValue: (value) => {
        state.calendarType = value;
      },
      openScheduleRegisterModal: (startAt, endAt, weekIndex) => {
        state.scheduleModalData.startAt = startAt;
        state.scheduleModalData.endAt = endAt;

        state.scheduleModalWeekIndex = weekIndex;

        state.showScheduleRegisterModal = true;
      },
      closeScheduleRegisterModal: () => {
        state.showScheduleRegisterModal = false;
      },
      openCloseModal: (timeGrid, weekIndex) => {
        state.scheduleCloseForm = {
          timeGrid: timeGrid,
          weekIndex: weekIndex,
        };
        state.showScheduleCloseModal = true;
      },
      closeScheduleDeleteModal: () => {
        state.scheduleCloseForm = {
          timeGrid: null,
          weekIndex: 0,
        };
        state.showScheduleCloseModal = false;
      },
      closeReservedScheduleCancelModal: () => {
        state.showReservedScheduleCancelModal = false;
      },
      moveToOpenHourReservation: () => {
        state.showReservedScheduleCancelModal = false;
        router.push({
          name: "console.openHourReservation",
        });
      },
    };

    return {
      state,
      actions,
      monthType,
      weekType,
      getSchedules,
      getReservations,
      saveSchedules,
      deleteLectureSchedule,
      cancelReservedSchedule,
      calendarComponent,
    };
  },
});
