<template>
  <base-modal
    title="스케줄을 삭제할까요?"
    sub-title="등록된 예약 가능 스케줄을 삭제해요."
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="button-wrapper">
        <button-basic
          text="취소"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          text="삭제"
          @action="actions.closeSchedule()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineComponent } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default defineComponent({
  name: "ScheduleDeleteModal",
  components: { ButtonBasic, BaseModal },
  emits: ["closeModal", "closeSchedule"],
  setup(props, { emit }) {
    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
      closeSchedule: () => {
        emit("closeSchedule");
      },
    };

    return { actions };
  },
});
</script>

<style src="./style.css" scoped></style>
